<template>
  <layout width="540" :dividers="false">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <select-chips
        v-model="state"
        :items="objectGroupList"
        multiple
        label="Object groups"
        item-text="name"
        item-value="id"
        chips
        class="ma-0"
        @drop-item="handleDropChip(state, $event, 'id')"
      />
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="handleConfirm"
      >
        {{ confirmText }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { ref } from '@vue/composition-api';

import layout from '@/components/popup/PopupLayoutDefault';
import SelectChips from '@/components/_base/SelectChips';

import handleDropChip from '@/utils/handleDropChip';
import { objectService } from '@/modules/common/api';

import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';

export default {
  name: 'PopupAddToGroups',
  components: {
    layout,
    SelectChips
  },
  props: {
    title: {
      type: String,
      default: 'Add objects to groups'
    },
    selectedItemIds: {
      type: Array,
      default: () => []
    },
    confirmText: {
      type: String,
      default: 'Save'
    },
    onConfirm: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const state = ref([]);

    const { objectGroupList } = useObjectGroups();

    const handleCancel = () => {
      emit('close');
    };
    const handleConfirm = async () => {
      const objectGroupIds = state.value.map(item => item.id);

      try {
        loading.value = true;
        await objectService.linkObjectsToObjects(
          objectGroupIds,
          props.selectedItemIds
        );
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };

    return {
      handleConfirm,
      handleCancel,
      loading,
      state,
      objectGroupList,
      handleDropChip
    };
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
